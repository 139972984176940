
.team {
  width: 100%;
  padding: 36px 24px;
  box-sizing: border-box;
  border-radius: 12px;
  background: #FFF;
  margin-top: 80px;
}
.team-index {
  text-align: right;
  font-size: 24px;
  margin: auto;
  margin-bottom: 56px;
}
.team-title{
  color: #0C2821;
  font-family: "Neue Haas Grotesk Display Bold";
  font-size: 44px;
  font-style: normal;
  font-weight: 500;
  text-align: left;
}

.teams {
  padding: 0;
}
.team-item {
  width: 100%;
  border-radius: 12px;
  /* background: #F3F7F7; */
  /* padding: 16px; */
  box-sizing: border-box;
  margin-bottom: 24px;
}


.team-item-title {
  color: #0C2821;

font-family: "Neue Haas Grotesk Display Pro";
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: 32px;
text-transform: uppercase;
text-align: left;
margin: 0;
}
.team-item-text {
  color: #0C2821;

font-family: "Neue Haas Grotesk Display Pro";
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 24px; /* 150% */
text-align: left;
margin: 0;
}
.team-item img{
  width: 100%;
  margin-bottom: 24px;
}
.team-desc {
  width: 100%;
}

/* 手机屏幕样式 (小于768px) */
@media (max-width: 767.98px) {
  .portfolio-introduce {
    width: 100%;
  }
  .slidingElementStyle1 {
    left:-1000%;
  
  };
}

 /* 平板屏幕样式 (768px 到 991px) */
 @media (min-width: 768px) and (max-width: 1110px) {
  .team-item {
    /* width: 370px!important; */
  }
  .teams  {
    right: 0;
  }

}
  

  /* 桌面屏幕样式 (大于992px) */
  @media (min-width: 768px) {

    .team {
      position: relative;
      height: 94vw;
      padding: 5vw 1.67vw;
      margin-top: 5.56vw;
      border-radius: 0.83vw;  
    }
    .teams {
      /* overflow: hidden;
      top: 230px;
      display: flex;
      position: absolute;
      right: 24px; */

      overflow: hidden;
      top: 16vw;
      display: flex;
      position: absolute;
      right: 1.67vw;
      width: 70.5vw;
      justify-content: space-between;
      gap: 1.74vw;
      height: 35vw;
      margin-top: 0;
    }
    .teams.top {
      top: 55vw;
    }
   
    .team-item {
      /* width: 495px; */
      border-radius: 12px;
      z-index: 98;
      position: absolute;
      
      width: calc((100% - 3.48vw) / 3)
    }
    .team-item:first-child {
      /* margin-right: 25px; */
      left: 0;
      z-index: 99;
    }
    .team-item:nth-child(2) {
      /* margin-right: 25px; */
      left: calc((100% + 1.74vw) / 3);
      z-index: 98;
    }
    .team-item:last-child {
      /* margin-right: 25px; */
      right: 0;
      z-index: 97;
    }
  

    .team-desc {
      border-radius: 12px;
      background: #F3FD65;
      height: 22.36vw;
      padding: 1.67vw;
      text-align: left;
      /* height: 31.5vw; */
      font-size: 1.1vw;
      box-sizing: border-box;
      width: calc((100% - 25px) / 2);
      
      width: 100%;
      overflow: auto;
      border-radius: 0.83vw;
      pointer-events: none; /* 子元素不会响应任何鼠标事件 */
    }
   
    .team-item img{
      width: 100%;
      /* height: 34.4vw; */
      height: 29.79vw;
      margin-right: 24px;
      cursor: pointer;
      border-radius: 0.83vw;
    }
    .team-item-title {
      font-size: 1.39vw;
      line-height: 2.2vw;
    }
    .team-item-text {
      font-size: 1.1vw;
      line-height: 1.67vw;
    }
    .team-index {
    position: absolute;
    top: 11vw;
    right: 1.67vw;
    font-size: 1.67vw;
    }
    .team-title {
      font-size: 4.17vw;
      margin: 0;
      /* margin-left: 23vw; */
      width: 47.5vw;
      margin: auto;

      /* width: 1015px; */
      /* position: absolute; */
      /* right: 24px; */
    }
    .team-content {
      /* width: 1440px; */
    }
  }
  