
.portfolio {
  width: 100%;
  padding: 36px 24px 16px 24px;
  box-sizing: border-box;
  border-radius: 12px;
  background: #FFF;
}
.portfolio-index {
  text-align: right;
  font-size: 24px;
  margin: auto;
  margin-bottom: 40px;
}
.portfolio-title{
  color: #0C2821;
  font-family: "Neue Haas Grotesk Display Bold";
  font-size: 44px;
  font-style: normal;
  font-weight: 500;
  margin-top: 0;
  margin-bottom: 25px;
  text-align: left;
}
.portfolio-introduce {
  color: #0C2821;
  font-style: normal;
  font-weight: 400;
  margin: auto;
  text-align: left;
}
.portfolios {
  padding: 0;
}
.portfolio-item {
  width: 100%;
  border-radius: 12px;
  background: #F3F7F7;
  padding: 16px;
  box-sizing: border-box;
  cursor: pointer;
}
.portfolio-item:hover {
  background: #F3FD65;
}
.portfolio-item-index {
  display: flex;
  padding: 0px 12px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 32px;
  background: #FFF;
  width: 16px;
  margin-top: 16px;
  color: #0C2821;

font-family: "Neue Haas Grotesk Display Bold";
font-size: 16px;
font-style: normal;
font-weight: 500;
}

.portfolio-item-title {
  color: #0C2821;

font-family: "Neue Haas Grotesk Display Bold";
font-size: 24px;
font-style: normal;
font-weight: 600;
line-height: 32px; /* 133.333% */
text-transform: uppercase;
text-align: left;
margin: 24px 0 8px 0;
}
.portfolio-item-text {
  color: #0C2821;
  font-family: "Neue Haas Grotesk Display Pro";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
  text-align: left;
  margin: 0;
}
.portfolio-item img{
  width: 100%;
}

/* 手机屏幕样式 (小于768px) */
@media (max-width: 767.98px) {
  .portfolio-introduce {
    width: 100%;
  }
  .portfolio-introduce {
    color: #0C2821;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px; 
    margin: auto;
    text-align: left;
  }
}
  

  /* 桌面屏幕样式 (大于992px) */
  @media (min-width: 768px) {
    .portfolio {
      position: relative;
      /* height: 864px; */
      height: 75.48vw;
      padding: 5vw 0 0 0;
      border-radius: 0.83vw;
    }
    /* .team-title{
      width: 47.5vw;
      color: #0C2821;
    
      font-family: "Neue Haas Grotesk Display Bold";
      font-size: 4.17vw;
      font-style: normal;
      font-weight: 500;
      margin: auto;
      text-align: left;
    } */
    .portfolios {
      width: 70.5vw;
      position: absolute;
      right: 24px;
      right: 1.67vw;
      display: flex;
      margin-top: 0;
    }
    .portfolio-title{
      width: 47.5vw;
      color: #0C2821;
      font-family: "Neue Haas Grotesk Display Bold";
      font-size: 4.17vw;
      font-style: normal;
      font-weight: 500;
      margin: auto;
      text-align: left;
      margin-bottom: 25px;
      margin-bottom: 1.74vw;
    }
    .portfolio-introduce {
      width: 47.5vw;
      color: #0C2821;
      font-family: "Neue Haas Grotesk Display Bold";
      font-size: 2.8vw;
      font-style: normal;
      font-weight: 400;
      line-height: 3.3vw; 
      margin: auto;
      text-align: left;
    }
    .portfolio-introduce {
      font-family: "Neue Haas Grotesk Display Bold" !important;
    }
    .project-index-list {
      width: 38.9vw;
      margin: 5.56vw auto;
  }
    
    .portfolio-item {
      display: flex;
      position: relative;
      cursor: pointer;
      padding: 24px;
      padding: 1.67vw;
      border-radius: 0.83vw;
    }
    .portfolio-item img {
      width: 16.7vw;
      margin-right: 1.67vw;
    }
    .portfolio-item-title {
      font-size: 1.67vw;
      line-height: 2.22vw;
      margin: 1.67vw 0 0.56vw 0;
    }
    .portfolio-item-text{
      font-size: 1.1vw;
        line-height: 1.67vw;
    }
    .portfolio-index {
      margin-bottom: 70px;
      margin-bottom: 5.56vw;
      padding: 0 24px;
      padding: 0 1.67vw;
      font-size: 1.67vw;
    }
    .portfolio-item-index {
      padding: 0px 0.83vw;
      border-radius: 2.22vw;
      width: 1.11vw;
      font-size: 1.11vw;
  }
  }
  