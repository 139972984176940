
.Services {
  width: 100%;
  position: relative;
  background: linear-gradient(to bottom, #D3E6EE, #F4D1C4);
  box-sizing: border-box;
}
.Services .banner-img {
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
}
.text {

  margin: auto;
  font-family: 'Neue Haas Grotesk Display Bold';
  text-align: left;
  color: #0C2821;
  font-size: 44px;
  font-style: normal;
  font-weight: 500;
}
.sub-text {
  font-family: 'Neue Haas Grotesk Display Pro';
  color: #0C2821;
  text-align: left;
  font-weight: 400;
  margin: 16px auto 24px auto;
}
.explore {
  position: absolute;
  /* left: 0; */
  right: 0;
  /* top: 300px; */
  margin: auto;
  font-family: 'Neue Haas Grotesk Display Pro';
  color: #0C2821;
  display: flex;
  justify-content: end;
  align-items: center;
}
.explore-button {
  background-color: #F3FD65;
  margin-left: 16px;
}
.explore-button img{
  width: 16px;
}
/* 手机屏幕样式 (小于768px) */
@media (max-width: 767.98px) {
  .Services {
    min-height: 542px;
    /* padding: 0 16px; */
  }
  .text {
    margin: 32px auto 0 auto;
    padding: 0 16px;
  }
  .sub-text {
    color: #0C2821;
    font-family: "Neue Haas Grotesk Display Pro";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    padding: 0 16px;
  }
  .explore {
    border-radius: 17px;
    right: 16px;
  }
  .explore-button {
    display: flex;
    padding: 10px 4px;
    align-items: center;
    gap: 10px;
    border-radius: 17px;
    background: #F3FD65;
  }
  .explore-button img{
    width: 16px;
  }

    .Services .banner-img {
        width: 100%;
    }
  }
  
  /* 平板屏幕样式 (768px 到 991px) */
  @media (min-width: 768px) and (max-width: 991.98px) {
    body {
      font-size: 16px;
    }
  
    .container {
      padding: 20px;
    }
  }
  
  /* 桌面屏幕样式 (大于992px) */
  @media (min-width: 768px) {
    .Services {
      /* height: 780px; */
      height: 54.17vw;
      /* padding-top: 8px; */
  }
  .text {
    width: 47.5vw;
    font-size: 60px;
    font-size: 4.17vw;
    margin-top: 32px;
    font-style: normal;
    font-weight: 400;
  }
  .sub-text {
    width: 47.5vw;
    font-size: 1.67vw;
  }
  .explore {
    width: 47.5vw;
    line-height: 45px;
    left: 0;
  }
  .explore-button {
    width: 24px;
    height: 36px;
    
    border-radius: 17px;
    margin-left: 10px;
  }
  .explore-button img{
    width: 16px;
  }

    .Services .banner-img {
        height: 428px;
        height: 29.72vw;
    }
   
   
  }
  