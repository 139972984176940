
.approach {
  width: 100%;
  padding: 40px 16px;
  box-sizing: border-box;
}
.approach-index {
  text-align: right;
  font-size: 24px;
  margin: auto;
  margin-bottom: 56px;
}
.approach-title{
  color: #0C2821;
  font-size: 44px;
  font-family: "Neue Haas Grotesk Display Bold";
  font-style: normal;
  font-weight: 500;
  line-height: 52px; /* 118.182% */
  margin: 0;
  text-align: left;
}
.approach-img {
  width: 100%;
  height: 160px;
}
.approachs {
  padding: 0;
  margin-top: 24px;
}
.approach-item {
  text-align: left;
  padding: 24px;
}
.approach-item-index {
  display: flex;
  padding: 0px 12px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 32px;
  background: #F3FD65;
  color: #0C2821;
  font-family: "Neue Haas Grotesk Display Pro";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  width: 16px;
  padding-top: 4px;
}
.approach-item-title {
  color: #0C2821;
  font-family: "Neue Haas Grotesk Display Bold";
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px; 
  margin: 24px 0 8px 0;
}
.approach-item-text {
  color: #0C2821;
  font-family: "Neue Haas Grotesk Display Pro";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; 
  margin: 0;
}
/* 手机屏幕样式 (小于768px) */
@media (max-width: 767.98px) {

}
  

  /* 桌面屏幕样式 (大于992px) */
  @media (min-width: 768px) {
    .approach {
      padding: 80px 0px;
      padding: 5vw 0px;
      min-height: 61.53vw;
    }
  .approachs {
    display: flex;
    margin-top: 48px;
    margin-top: 3.33vw;
  }
  .approach-title{
    display: flex;
    align-items: center;
    width: 47.5vw;
    height: 4.17vw;
    font-size: 4.17vw;
    margin: auto;
    margin-top: 72px;
    margin-top: 5vw;

  }
  .approach-img {
    height: 360px;
    height: 25vw;
    border-radius: 0.83vw;
  }
   .approach-item {
    padding: 0px 24px;
   }
   .approach-item{
    border-left: 1px solid #E1E6E6;
    /* border-right: 1px solid #E1E6E6; */
    background: #F3F7F7;
   }
   .approach-item:first-child {
    border: none;
   }
   .approach-item-title{
    font-style: normal;
    font-weight: 500;
    margin-top: 64px;
    font-size: 1.67vw;
    line-height: 2.22vw;
   }
   .approach-item-text {
    font-size: 1.1vw;
    line-height: 1.67vw;
    font-family: "Neue Haas Grotesk Display Bold";
   }
   .approach-index {
    font-size: 1.67vw;
    padding: 0 1.67vw;
    margin-bottom: 3.89vw;
   }
   .approach-item-index{
    width: 1.04vw;
    height: 1.67vw;
    border-radius: 2.22vw;
    font-size: 1.11vw;
    padding: 0 0.83vw;
   }
  }
  