
.footer {
  background: #0C2821;
  color: #FFF;
  padding: 72px 24px;
  box-sizing: border-box;
  overflow: hidden;
}
.footer .logo {
  color: #FFF;
  font-family: "Neue Haas Grotesk Display Bold";
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
}
.footer-inner {
  border: 1px solid rgba(255, 255, 255, 0.25);
  border-radius: 12px;
}

.footer-right {
  font-size: 20px;
  display: flex;
  padding: 24px;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
}
.footer-right ul {
  padding: 0;
}
.footer-right-item {
  color: #FFF;

  font-family: "Neue Haas Grotesk Display Pro";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  display: flex;
    align-items: center;
    margin-bottom: 16px;
    cursor: pointer;
}
.footer-right-item a {
  display: flex;
  align-items: center;
}
.footer-right-item:hover {
  color: #F3FD65;
}
.footer-right img{
  width: 24px;
  margin-right: 16px;
}
/* 手机屏幕样式 (小于768px) */
@media (max-width: 767.98px) {
  .footer {
    margin-top: 36px;
  }

  .footer-left {
    position: relative;
    height: 552px;
    padding: 24px;
    box-sizing: border-box;
    


  }
  .footer-title {
    position: absolute;
    top: 80px;
    font-size: 44px;
    text-align: left;
    font-family: "Neue Haas Grotesk Display Bold";
    font-weight: 500;
  }
  .box {
    width: 100%;
    height: 600px;
  }
  .footer-right {
    border-top: 1px solid rgba(255, 255, 255, 0.25);
    padding: 24px;
    text-align: left;
    height: 300px;
  }
}
  

  /* 桌面屏幕样式 (大于992px) */
  @media (min-width: 768px) {
    .footer {
     
        margin-top: 80px;
        height: 600px;
        height: 51.7vw;
    }
    .footer .logo {
      margin: 0;
      font-size: 1.67vw;
    }
    .footer .logo img {
      height: 2.43vw;
    }
    .footer .box {
      width: 100%;
      height: 300px;
      height: calc(100% - 15.76vw);
        position: absolute;
        top: 4.17vw;
    }
    .footer-left {
      /* width: 68.7vw;*/
      /* 
      height: 552px;
      padding: 24px;
      box-sizing: border-box; */
      position: relative;
      display: flex;
      padding: 24px;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      flex: 1 0 0;
      align-self: stretch;
    }
    .footer-title {
      position: absolute;
      text-align: left;
      font-size: 4.4vw;
      font-style: normal;
      font-weight: 500;
      bottom: 0;
      font-family: "Neue Haas Grotesk Display Bold"
    }
    .footer-inner {
      background: #0C2821;
      display: flex;
      width: 100%;
      height: 100%;
      /* padding: 72px 24px; */
      /* flex-direction: column; */
      align-items: flex-start;
      gap: 10px;
    }
    .footer-content {
      width: 100%;
    }
    .footer-right {
      /* font-size: 20px;
      display: flex;
      padding: 24px;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      align-self: stretch; */
      border-left: 1px solid rgba(255, 255, 255, 0.25);

      display: flex;
      width: 355px;
      padding: 24px;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      align-self: stretch;
      font-size: 1.39vw;
  }
  .footer-right-item {
    font-size: 1.39vw;
  }
  }
  