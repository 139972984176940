.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@font-face {
  font-family: 'Neue Haas Grotesk Display Pro';
  src: url('../public/font/NeueHaasDisplayLight.ttf') format('woff2'),
       url('../public/font/NeueHaasDisplayLight.ttf') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Neue Haas Grotesk Display Bold';
  src: url('../public/font/NeueHaasDisplayRoman.ttf') format('woff2'),
       url('../public/font/NeueHaasDisplayRoman.ttf') format('woff');
  font-weight: 400;
  font-style: normal;
}

a {
  text-decoration: none; /* 移除下划线 */
  color: inherit;        /* 继承父元素的颜色 */
  cursor: pointer;       /* 保持鼠标手势 */
}

a:visited {
  color: inherit;        /* 访问过的链接也继承颜色 */
}

a:hover {
  text-decoration: none; /* 悬停时保持无下划线 */
  color: inherit;        /* 悬停时继承颜色 */
}

a:active {
  text-decoration: none; /* 激活时无下划线 */
  color: inherit;        /* 激活时继承颜色 */
}

html,body {
  width: 100%;
  overflow-x: hidden;
}