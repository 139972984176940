
.news {
  width: 100%;
  padding: 24px 16px;
  box-sizing: border-box;
  border-radius: 12px;
background: #FFF;
margin-top: 80px;
}
.news-index {
  text-align: right;
}
.news-title{
  color: #0C2821;
  font-family: "Neue Haas Grotesk Display Bold";
  font-size: 44px;
  font-style: normal;
  font-weight: 500;
  margin-top: 0;
  margin-bottom: 25px;
  text-align: left;
}

.news-list {
  padding: 0;
}
.news-item {
  width: 100%;
  border-radius: 12px;
  padding: 16px;
  box-sizing: border-box;
  margin-bottom: 24px;
background: #F2F2F2;
}
.news-item:hover {
  background: #F3FD65;
}
.news-index {
  text-align: right;
  font-size: 24px;
  margin: auto;
  margin-bottom: 28px;
}


.news-item-text {
  color: #0C2821;
  font-family: "Neue Haas Grotesk Display Bold";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
  text-align: left;
  margin: 0;
  margin-top: 16px;
}
.news-item-date {
  color: #0C2821;
  margin-bottom: 64px;
  font-family: "Neue Haas Grotesk Display Bold";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  text-align: left;
  position: relative;
  padding-left: 18px;
}
.news-item-date::before {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 8px; /* 圆点直径 */
  height: 8px; /* 圆点直径 */
  background-color: black; /* 圆点颜色 */
  border-radius: 50%; /* 圆形 */
}
.news-item img{
  width: 100%;
}
.news-item-share {
  display: flex;
  align-items: center;
  justify-content: end;
  color: #0C2821;
  font-family: "Neue Haas Grotesk Display Bold";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  margin-top: 64px;
  cursor: pointer;
}
.news-item-share img {
  width: 16px;
height: 16px;
flex-shrink: 0;
}

/* 手机屏幕样式 (小于768px) */
@media (max-width: 767.98px) {
  .portfolio-introduce {
    width: 100%;
  }
}
  

  /* 桌面屏幕样式 (大于992px) */
  @media (min-width: 768px) {

   .news {
    padding: 72px 24px;
    padding: 5vw 1.67vw;
    border-radius: 0.83vw;
    margin-top: 5.56vw;
   }
   .news-title {
    font-size: 4.17vw;
    width: 47.5vw;
    margin: auto;
   }
    .news-list {
      display: flex;
    }
    .news-item {
      border-radius: 0.83vw;
      padding: 1.11vw;
      margin-bottom: 1.67vw;
    }
    .news-item:nth-child(2) {
      margin-left:24px ;
      margin-right: 24px;
    }
    .news-index{
      font-size: 1.67vw;
      margin-bottom: 3.89vw;
    }
    .news-item-date{
      font-size: 0.97vw;
      margin-bottom: 4.44vw;
    }
    .news-item-text {
      font-size: 1.39vw;
      line-height: 1.94vw;
      margin-bottom: 4.44vw;
    }
  }
  