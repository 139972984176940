
.header {
  position: relative;
   display: flex;
   justify-content: space-between;
   align-items: center;
   box-sizing: border-box;
}
.header .menu {
 transition: all 0.5s ease; /* 动画过渡 */
}
.left {
   display: flex;
   align-items: center;
   cursor: pointer;
}
.left p {
 color: #0C2821;
 font-family: "Neue Haas Grotesk Display Bold";
 font-size: 16.457px;
 font-style: normal;
 font-weight: 600;
}
.left img {
   height: 24px;
}
.right {
   cursor: pointer;
}
.right img{
   height: 10px;
   height: 0.7vw;
}

/* 手机屏幕样式 (小于768px) */
@media (max-width: 767.98px) {
   .header {
       height: 48px;
       padding: 0 16px;
   }
 }
 
 /* 平板屏幕样式 (768px 到 991px) */
 @media (min-width: 768px) and (max-width: 991.98px) {
   body {
     font-size: 16px;
   }
 
   .container {
     padding: 20px;
   }
 }
 
 /* 桌面屏幕样式 (大于992px) */
 @media (min-width: 768px) {
   .header {
       height: 46px;
       height: 3.19vw;
       padding: 0 24px;
       padding: 0 1.67vw;
   }
   .header .menu {
     /* top: -770px; */
   }
   

   .left img {
       height: 1.67vw;
       margin-right: 3px;
   }

   .left p {
     font-size: 1.15vw;
   }
  
 }
 