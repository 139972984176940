
.menu {
  width: 100%;
  background: #0C2821;
  position: absolute;
  color: #fff;
  font-family: "Neue Haas Grotesk Display Pro";
  padding: 8px 24px 40px 24px;
  box-sizing: border-box;
}

ul li {
  list-style: none;
}
.menu-top{
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 32px;
}
.logo {
  display: flex;
  align-items: center;
  font-size: 16.457px;
  font-style: normal;
  font-weight: 600;
  font-family: "Neue Haas Grotesk Display Bold";
}
.logo img{
  height: 24px;
  margin-right: 4px;
}
.close img{
  height: 30px;
  cursor: pointer;
}


.menu-right li{
  display: flex;
  align-items: center;
  text-align: left;
 }
 .menu-right li:first-child {
  opacity: 0.35;
 }
.menu-right li img{
  width: 24px;
  margin-right: 16px;
}
.menu-right li a{
  display: flex;
  align-items: center;
}
.menu-content {
  margin-top: 40px;
}
/* 手机屏幕样式 (小于768px) */
@media (max-width: 767.98px) {
  .menu {
    height: 783px;
    padding: 8px 24px 40px 24px;
    
  }
  .menu-left {
    font-size: 40px;
    font-style: normal;
    font-weight: 500;
    margin-top: 40px;
    font-family: "Neue Haas Grotesk Display Bold";
  }
  .menu-right {
    /* width: 276px; */
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    margin-bottom: 24px;
    margin-top: 60px;
    margin-bottom: 68px;
  }
  .menu-left li{
    cursor: pointer;
    text-align: left;
    font-style: normal;
    font-weight: 500;
    line-height: 48px; /* 120% */
  }
  .menu-left li a{
    display: flex;
    align-items: center;
  }
  .menu-left li img {
    opacity: 0;
    visibility: hidden; /* 初始状态不可见 */
    transition: opacity 0.5s ease, visibility 0.5s ease; /* 添加过渡效果 */
    transition: all 0.5s ease; /* 动画过渡 */
    width: 96px;
  }
  .menu-left li:hover{
    color: #F3FD65;
  }
  .menu-left li:hover img{
    opacity: 1;
    visibility: visible; /* 显示时变为可见 */
    margin-left: 96px;
  } 
  .menu-right li{
    margin-bottom: 24px;
  }
  /* .left img {
      height: 24px;
      margin-right: 3px;
  } */
  .menu-content p {
    text-align: left;
    margin-left: 48px;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
  }
  }
  
 
  
  /* 桌面屏幕样式 (大于992px) */
  @media (min-width: 768px) {
    .menu {
      height: 783px;
      height: 54.37vw;
      padding: 0.56vw 1.67vw 2.78vw 1.67vw;
      
    }
    .menu-top{
      height: 3.47vw;
    }
    .menu-top .logo {
      font-size: 1.15vw;
    }
    .menu-top .logo img {
      height: 1.67vw;
    }
    .menu-content{
      display: flex;
      justify-content: space-between;
      position: relative;
    }
    .menu-left {
      /* font-size: 96px; */
      font-size: 6.67vw;
      font-style: normal;
      font-weight: 500;
      margin-top: 24px;
      font-family: "Neue Haas Grotesk Display Pro";
    }
    .menu-right {
      /* width: 276px; */
      width: 19.2vw;
      font-size: 20px;
      font-size: 1.39vw;
      font-style: normal;
      font-weight: 500;
      margin-bottom: 24px;
      font-family: "Neue Haas Grotesk Display Pro";
      padding: 0 2.78vw;
      border-left: 1px solid rgba(255, 255, 255, 0.14);
      height: 43.1vw;

    }
    .menu-right li:not(:first-child):hover {
      color: #F3FD65;
    }
    .menu-left li{
      cursor: pointer;
      text-align: left;
    }
    .menu-left li a{
      display: flex;
      align-items: center;
    }
    .menu-left li img {
      opacity: 0;
      visibility: hidden; /* 初始状态不可见 */
      transition: opacity 0.5s ease, visibility 0.5s ease; /* 添加过渡效果 */
      transition: all 0.5s ease; /* 动画过渡 */
      width: 96px;
      width: 6.67vw;
      
    }
    .menu-left li:hover{
      color: #F3FD65;
    }
    .menu-left li:hover img{
      opacity: 1;
      visibility: visible; /* 显示时变为可见 */
      margin-left: 96px;
    } 
    .menu-right{
      margin-top: 0;
    }
    .menu-right li{
      margin-bottom: 24px;
      margin-bottom: 1.67vw;
    }
    /* .left img {
        height: 24px;
        margin-right: 3px;
    } */
    .menu-content p {
      position: absolute;
      bottom: 70px;
      left: 80%;
      left: 75vw;
      font-size: 1.39vw;
    }
    .menu-right li img {
      width: 1.67vw;
  }
  .close img{
    height: 2.08vw;
  }
   
  }

 
  