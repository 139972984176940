
.Mission {
  padding: 80px 24px;
  background: #fff;
}
.Mission .box {
  /* width: 51.80vw; */
  height: calc(100% - 32px);
  height: 352px;
  margin: auto;
}
.Mission .title {
  font-size: 44px;
  font-style: normal;
  font-weight: 500;
  text-align: left;
  margin: auto;
  font-family: "Neue Haas Grotesk Display Bold";
}
.Mission .sub-title {
  color: #0C2821;
  font-family: "Neue Haas Grotesk Display Pro";
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  /* line-height: 48px; */
  margin: 25px auto 0 auto;
  text-align: left;
  /* margin: auto; */

}
.Mission .index {
  width: 100%;
  margin: auto;
  text-align: right;
  margin-bottom: 20px;
  text-align: right;
  font-size: 24px;
  margin: auto;
  margin-bottom: 20px;
}
.missions {
  /* position: absolute; */
    margin: auto;
    /* left: 25vw; */
}
.our-mission {
  color: #0C2821;
  font-family: "Neue Haas Grotesk Display Pro";
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  /* line-height: 40px; */
  text-align: left;
}
.missions .mission-item img{
  width: 80px;
  height: 80px;
}



.mission-item-content .mission-item-index {
  width: 18px;
    height: 24px;
    display: flex;
    padding: 0px 12px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 32px;
    border: 1px solid #0C2821;
    margin: 32px 0 24px 0;

}
.mission-item-content .mission-item-text {
  color: #0C2821;

font-family: "Neue Haas Grotesk Display Pro";
font-size: 24px;
font-style: normal;
font-weight: 500;
/* line-height: 32px;  */
}
/* 手机屏幕样式 (小于768px) */
@media (max-width: 767.98px) {
  .missions .mission-item {
    text-align: left;
    margin: 24px 0;
  }
  .missions {
    width: 100%;
    padding: 0;
  }
  .Mission .sub-title {
    margin: 16px auto 16px auto;
  
  }
  }
  
  /* 平板屏幕样式 (768px 到 991px) */
  @media (min-width: 768px) and (max-width: 991.98px) {
    body {
      font-size: 16px;
    }
  
    .container {
      padding: 20px;
    }
  }
  
  /* 桌面屏幕样式 (大于992px) */
  @media (min-width: 768px) {
    .Mission{
      height: auto;
      min-height: 101.87vw;
    }
    .Mission .box {
      position: relative;
      width: 31.80vw;
      height: 24.86vw;
      overflow: hidden;
    }

    .Mission .box spline-viewer {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100vw; /* Set to the desired fixed width */
      height: auto; /* Adjust height to maintain aspect ratio */
      transform: translate(-50%, -50%);
    }    

    .mission-container {
      height: 456px;
    }
    .our-mission {
      font-size: 2.2vw;
      position: absolute;
      left: 48px;
      margin-top: 54px;
    }
    .Mission .title {
      width: 47.5vw;
      font-size: 4.17vw;
    }
    .Mission .sub-title {
      width: 47.5vw;
      font-size: 2.8vw;
    }
    .missions {
      width: 70.41vw;
      position: absolute;
      left: 25vw;
      margin-top: 54px;
    }
    .missions .mission-item {
      display: flex;
      align-items: center;
      margin-bottom: 48px;
      height: 6.04vw;
    }
    .missions .mission-item img{
      width: 6.04vw;
      height: 6.04vw;
    }
    .mission-item-content{
      width: 57.36vw;
      text-align: left;
      margin-left: 68px;
    }
    .mission-item-content .mission-item-text {
      font-size: 1.7vw;
    }
    .Mission .index {
      /* width: 70.41vw;
       */
       padding: 0px 24px;
       box-sizing: border-box;
       font-size: 1.67vw;
    }
    .sub-title {
      margin-bottom: 0;
    }
   
  }
  