
.home {
  overflow-y: auto;
  /* height: 7484px; */
  background: #F3F7F7;
  overflow-x: hidden;
  
}
.project-modal.ant-modal .ant-modal-close {
  width: 2.78vw;
  height: 2.78vw;
  top: 1.67vw!important;
  right: 2.78vw!important;
  margin-right: 0;
  inset-inline-end: 2.78vw!important;
}
.ant-drawer .ant-drawer-header {
  padding-top: 24px!important;
}
.ant-drawer .ant-drawer-close {
  margin-right: 0;
}

.ant-modal-close:hover{
  background-color: transparent !important;;
}
.ant-modal-body {
  padding: 0!important;
}
.project-modal .ant-modal-content {
  padding-top: 84px;
}
.project-img {
  width: 100%;
}
.project-index-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.project-desc-name {
  color: #0C2821;
  font-family: "Neue Haas Grotesk Display Bold";
  font-size: 44px;
  font-size: 4.17vw;
  font-style: normal;
  font-weight: 500;
}
.project-desc-text {

  font-size: 32px;
  margin: auto;
  color: #0C2821;
  font-family: "Neue Haas Grotesk Display Pro";
  font-style: normal;
  font-weight: 400;
}
.project-index-item {
  flex-basis: 50%;
  margin-bottom: 40px;
}
.item-num {
  font-size: 44px;
}
.item-num {
  position: relative;
  font-size: 44px;
  color: #0C2821;
  font-style: normal;
  font-weight: 500;
  font-family: "Neue Haas Grotesk Display Bold";
}
.item-num span {
  position: absolute;
  font-size: 24px;
  top: 10px;
  left: -14px;
  font-family: "Neue Haas Grotesk Display Bold";
}
.item-name {
  font-family: "Neue Haas Grotesk Display Bold";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  margin: 0;
  margin-left: -10px;
}
.mb35 {
  margin-bottom: 35px;
}
.ant-drawer-header-title  {
  justify-content: right;
}
.ant-drawer-header {
  border-bottom: none!important;
}
#logo {
  display: none!important;
}
/* 手机屏幕样式 (小于768px) */
@media (max-width: 767.98px) {
  .home {
    /* padding: 0 16px; */
  overflow-x: hidden;
  }
  .home-bg {
    padding: 0 16px;
  }
  .project-modal {
    width: 100%!important;
  }
  .ant-modal-close-x img {
    width:40px!important;
  }
  .ant-drawer-body {
    padding-top: 0!important;
  }
}
@media (min-width: 768px) {
  .home {
  }
  .home-bg {
    padding: 0 24px;
    padding: 0 1.67vw;
    background: #F3F7F7;
  }
  .project-desc-name {
    width: 38.9vw;
    margin: 40px auto;

  }
  .project-desc-text {
    width: 38.9vw;
    font-size: 2.8vw;
    width: 38.9vw;
    font-size: 2.8vw;
    margin: auto;
    color: #0C2821;
    font-family: "Neue Haas Grotesk Display Pro";
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    font-size: 2.78vw;
    line-height: 3.33vw;
  }
  .item-num {
    font-size: 4.4vw;
  }
  
  .item-num span {
    font-size: 24px;
    top: 16px;
    left: -14px;
    font-size: 1.67vw;
    top: 1.11vw;
    left: -0.97vw;
}
.left-margin {
  left: 27.78vw;
}
.item-name {
  font-size: 1.11vw;
}
.project-modal .ant-modal-content {
  padding: 5.83vw 2.78vw 2.78vw 2.78vw;
  border-radius: 0.83vw;
}
.project-img {
  border-radius: 0.83vw;
}
.ant-modal .ant-modal-close-x {
 
  height: 2.78vw;
  width: 2.78vw;
}
.ant-modal-close-x img {
  margin: 0!important;
}
.mb35 {
  margin-bottom: 2.43vw;
} 
}
